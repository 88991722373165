import React, {useState, useRef} from 'react';

const SummaryParticipant = ({participant}) => {
    const [open, setOpen] = useState(false);
    const body = useRef();

    return(
        <div className="vrb__summary--participant">
            <div className="vrb__summary--participant-header">
                <div>{`${participant.first_name} ${participant.last_name}`}</div>
                <span
                    onClick={() => setOpen(!open)}
                >
                    <svg
                        viewBox="0 0 491.996 491.996"
                        style={{
                            transform: open ? 'rotate(90deg)' : null
                        }}
                    >
                        <path d="m484.132 124.986-16.116-16.228c-5.072-5.068-11.82-7.86-19.032-7.86-7.208 0-13.964 2.792-19.036 7.86l-183.84 183.848-184.052-184.052c-5.064-5.068-11.82-7.856-19.028-7.856s-13.968 2.788-19.036 7.856l-16.12 16.128c-10.496 10.488-10.496 27.572 0 38.06l219.136 219.924c5.064 5.064 11.812 8.632 19.084 8.632h.084c7.212 0 13.96-3.572 19.024-8.632l218.932-219.328c5.072-5.064 7.856-12.016 7.864-19.224 0-7.212-2.792-14.068-7.864-19.128z"/></svg>
                </span>
            </div>
            <div
                ref={body}
                className="vrb__summary--participant-body"
                style={{
                    height: open ? body.current.scrollHeight + 'px' : '0px'
                }}
            >
                <div className="row">
                    <svg viewBox="0 0 511.999 511.999"><path d="m498.827 377.633-63.649-63.649c-17.548-17.547-46.102-17.547-63.649 0l-28.931 28.931c-13.294 13.294-34.926 13.29-48.215.005l-125.4-125.507c-13.325-13.325-13.327-34.892 0-48.219 4.66-4.66 18.041-18.041 28.931-28.931 17.471-17.47 17.715-45.935-.017-63.665l-63.632-63.432c-17.548-17.547-46.101-17.547-63.602-.046-13.096 12.982-17.32 17.17-23.192 22.991-63.28 63.279-63.28 166.242-.003 229.519l198.692 198.796c63.428 63.429 166.088 63.434 229.521 0l23.146-23.145c17.548-17.548 17.548-46.1 0-63.648zm-406.994-343.251c5.849-5.849 15.365-5.85 21.233.016l63.632 63.432c5.863 5.863 5.863 15.352 0 21.216l-10.609 10.608-84.81-84.81zm175.547 408.831-198.693-198.798c-48.958-48.958-51.649-125.833-8.276-178.006l84.564 84.564c-22.22 25.189-21.294 63.572 2.787 87.653l125.396 125.501.004.004c24.055 24.056 62.436 25.042 87.656 2.792l84.566 84.566c-52.007 43.298-128.709 41.019-178.004-8.276zm210.232-23.148-10.609 10.609-84.865-84.866 10.607-10.608c5.85-5.849 15.367-5.85 21.217 0l63.649 63.649c5.85 5.85 5.85 15.368.001 21.216z"/></svg>
                    <span>{participant.mobile}</span>
                </div>
                <div className="row">
                    <svg viewBox="0 0 512 512"><path d="m467 61h-422c-24.782 0-45 20.196-45 45v300c0 24.72 20.128 45 45 45h422c24.72 0 45-20.128 45-45v-300c0-24.72-20.128-45-45-45zm-6.214 30-203.832 203.833-205.595-203.833zm-430.786 308.788v-287.719l144.479 143.24zm21.213 21.212 144.57-144.57 50.657 50.222c5.864 5.814 15.327 5.795 21.167-.046l49.393-49.393 143.787 143.787zm430.787-21.213-143.787-143.787 143.787-143.788z"/></svg>
                    <span>{participant.email}</span>
                </div>
            </div>
        </div>
    );
};

export default SummaryParticipant;