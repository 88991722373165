import Hero from "../../../../src/components/Hero";
import AnimatedHeadline from "../../../../src/components/AnimatedHeadline";
import PageContent from "../../../../src/components/PageContent";
import List from "../../../../src/components/List";
import Reviews from "../../../../src/components/Reviews";
import Vabs from "../../../../src/components/vabs";
import * as React from 'react';
export default {
  Hero,
  AnimatedHeadline,
  PageContent,
  List,
  Reviews,
  Vabs,
  React
};